import { Box, Button, Card, CardMedia, Stack } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useGraveIndexPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    // initial fetch
    const { data: grave } = useQuerySuspense(
        [`/grave`],
        async () => {
            return await apiClient.clientGraveGet({})
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )

    useEffect(() => {
        if (grave?.file === null) navigate('/grave/edit', { replace: true })
    }, [])

    return {
        grave,
        navigate,
    }
}

export const GraveIndexPage: FC = () => {
    const { grave, navigate } = useGraveIndexPage()
    return (
        <>
            <DefaultLayout title={''} breadcrumbList={[]}>
                <Stack spacing={2}>
                    <Box display="flex" justifyContent="center">
                        <Card
                            variant="outlined"
                            elevation={0}
                            sx={{
                                width: 327,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                border: 'none',
                                boxShadow: 'none',
                                borderRadius: 0,
                                background: 'none',
                            }}>
                            <CardMedia component="img" height="400" width="327" image={mediaUrl(grave?.file)} alt="" />
                        </Card>
                    </Box>

                    <Button variant={'contained'} onClick={() => navigate('/grave/edit')}>
                        編集
                    </Button>
                </Stack>
            </DefaultLayout>
        </>
    )
}
