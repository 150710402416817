import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'

import connectImage from '~/assets/image/connect/connectImage.svg'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'

import { CQRCode } from '../../cQRCode/CQRCode'

export const useCUserQrCodeDialog = (text: string) => {
    const { queueDialog } = useConfirmationDialog()
    const { me } = useAuthState()
    const [isFacebookLoading, setIsFacebookLoading] = useState(false)
    // Facebookではlocalhostが許可されておらず、ViteのIPアドレスはコンピュータごとに変わるため、ローカル環境では手動でlocalhostを0.0.0.0に置き換える必要があります。
    const baseUrl = process.env.STAGE === 'local' ? 'http://0.0.0.0:3000' : process.env.BASE_URL
    const replacedText = text.replace(process.env.BASE_URL as string, baseUrl as string)
    const currentPage = window.location.href

    const sendInviteByLine = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
            replacedText + '\n' + `${me?.name}さんから「これ終〜これからの終活〜」に招待されています。`,
        )}`
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    const sendInviteByFacebook = async () => {
        setIsFacebookLoading(true)

        const facebookUrl = `https://www.facebook.com/dialog/send?app_id=${
            process.env.FACEBOOK_CLIENT_ID
        }&link=${encodeURIComponent(replacedText)}&redirect_uri=${currentPage}`
        const mobileUrl = `fb-messenger://share?link=${text}`
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
        const downloadUrl = isIOS
            ? 'https://apps.apple.com/app/messenger/id454638411'
            : 'https://play.google.com/store/apps/details?id=com.facebook.orca'

        if (isMobile) {
            window.location.href = mobileUrl
            setTimeout(() => {
                if (document.visibilityState === 'visible') window.open(downloadUrl, '_blank', 'noopener,noreferrer')

                setIsFacebookLoading(false)
            }, 1500)
        } else {
            window.open(facebookUrl, '_blank', 'noopener,noreferrer')
            setIsFacebookLoading(false)
        }
    }
    const copyHandler = async () => {
        navigator.clipboard.writeText(text)
        await queueDialog({
            type: 'alert',
            title: '招待URLをクリップボードにコピーしました',
            text: '',
        })
    }
    return {
        copyHandler,
        sendInviteByLine,
        sendInviteByFacebook,
        isFacebookLoading,
    }
}

export type CUserQrCodeDialogProps = {
    isOpen: boolean
    onClose: () => void
    text: string
}

export const CUserQrCodeDialog: FC<CUserQrCodeDialogProps> = ({ isOpen, onClose, text }) => {
    const { copyHandler, sendInviteByLine, sendInviteByFacebook, isFacebookLoading } = useCUserQrCodeDialog(text)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent sx={{ bgcolor: '#F2F2F2', p: 0 }}>
                    <Box sx={{ p: 3 }}>
                        <Stack spacing={'1rem'}>
                            <Stack justifyContent={'center'} alignItems={'center'}>
                                <img src={connectImage} />
                            </Stack>

                            <Stack spacing={'0.5rem'} justifyContent={'center'} alignItems={'center'}>
                                <Typography variant={'subtitle2'}>つながり招待</Typography>
                                <CQRCode text={text} />
                            </Stack>
                            <Button variant={'contained'} disableElevation onClick={copyHandler}>
                                招待URLをコピー
                            </Button>
                            <Button variant={'contained'} disableElevation onClick={sendInviteByLine}>
                                LINEで招待
                            </Button>
                            <LoadingButton
                                loading={isFacebookLoading}
                                variant={'contained'}
                                disableElevation
                                onClick={sendInviteByFacebook}>
                                Facebookで招待
                            </LoadingButton>
                        </Stack>
                    </Box>
                    <Divider />
                    <Button fullWidth onClick={onClose} sx={{ py: '1rem' }}>
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}
