import dayjs from 'dayjs'
import * as z from 'zod'

// openapiからの自動生成に置き換え予定

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaForType =
    <T>() =>
    <S extends z.ZodType<T>>(arg: S) => {
        return arg
    }

export const clientUserMobilePhoneTokenRequestDtoSchema = z.object({
    mobilePhone: z.string().min(1, '必須項目です'),
    inviteCode: z.string().nullish(),
})
export type ClientUserMobilePhoneTokenRequestDtoSchema = z.infer<typeof clientUserMobilePhoneTokenRequestDtoSchema>

export const clientUserEmailTokenRequestDtoSchema = z.object({
    email: z.string().min(1, '必須項目です'),
    inviteCode: z.string().nullish(),
})
export type ClientUserEmailTokenRequestDtoSchema = z.infer<typeof clientUserEmailTokenRequestDtoSchema>

export const userLoginUserMobilePhoneDtoSchema = z.object({
    email: z.string(),
    mobilePhone: z.string(),
    password: z.string(),
})
export type UserLoginUserMobilePhoneDtoSchema = z.infer<typeof userLoginUserMobilePhoneDtoSchema>

export const userLoginUserEmailDtoSchema = z.object({
    email: z.string(),
    password: z.string(),
})
export type UserLoginUserEmailDtoSchema = z.infer<typeof userLoginUserEmailDtoSchema>

export const userLoginUserDtoSchema = z.object({
    email: z.string().email(),
    password: z.string(),
})
export type UserLoginUserDtoSchema = z.infer<typeof userLoginUserDtoSchema>

export const clientUserCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    mobilePhone: z.string(),
    email: z.string(),
    token: z.string().min(1, '必須項目です'),
    inviteCode: z.string().nullish(),
    fileUuid: z.string().nullish(),
    sei: z.string().min(0),
    mei: z.string().min(0),
})
export type ClientUserCreateDtoSchema = z.infer<typeof clientUserCreateDtoSchema>

export const clientUserUpdateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    birthday: z.string().nullable(),
    fileUuid: z.string().nullish(),

    sei: z.string().min(1, '必須項目です'),
    mei: z.string().min(1, '必須項目です'),
    postalCode: z.string().nullish(),
    pref: z.string().nullish(),
    city: z.string().nullish(),
    address: z.string().nullish(),
    building: z.string().nullish(),
    tel: z.string().nullish(),
})
export type ClientUserUpdateDtoSchema = z.infer<typeof clientUserUpdateDtoSchema>

export const clientUserMobilePhoneUpdateDtoSchema = z.object({
    mobilePhone: z.string().min(1, '必須項目です'),
})
export type ClientUserMobilePhoneUpdateDtoSchema = z.infer<typeof clientUserMobilePhoneUpdateDtoSchema>

export const clientUserMailUpdateDtoSchema = z.object({
    email: z.string().email('正しいメールアドレスを入力してください'),
})
export type ClientUserMailUpdateDtoSchema = z.infer<typeof clientUserMailUpdateDtoSchema>

export const clientUserMailNicknameUpdateDtoSchema = z.object({
    email: z.string().nullable(),
    nickname: z.string().min(1, '必須項目です'),
})
export type ClientUserMailNicknameUpdateDtoSchema = z.infer<typeof clientUserMailNicknameUpdateDtoSchema>

export const clientUserReminderCredentialDtoSchema = z.object({
    token: z.string().min(1, '必須項目です'),
    password: z.string().min(1, '必須項目です'),
})
export type ClientUserReminderCredentialDtoSchema = z.infer<typeof clientUserReminderCredentialDtoSchema>

export const clientUserAddressDtoSchema = z.object({
    sei: z.string().min(1, '必須項目です'),
    mei: z.string().min(1, '必須項目です'),

    postalCode: z.string().min(1, '必須項目です'),
    pref: z.string().min(1, '必須項目です'),
    city: z.string().min(1, '必須項目です'),
    address: z.string().min(1, '必須項目です'),
    building: z.string().nullable(),
    tel: z.string().nullable(),
    mobilePhone: z.string().nullable(),
    main: z.boolean().optional(),
})
export type ClientUserAddressDtoSchema = z.infer<typeof clientUserAddressDtoSchema>

/**
 * お問い合わせ
 */
export const inquiryCreateDtoSchema = z.object({
    category: z.string().optional(),
    title: z.string().min(1, '必須項目です'),
    content: z.string().min(1, '必須項目です'),
    name: z.string().min(1, '必須項目です'),
    email: z.string().min(1, '必須項目です'),
    tel: z.string().optional(),
    userUuid: z.string().optional(),
})
export type InquiryCreateDtoSchema = z.infer<typeof inquiryCreateDtoSchema>

export const inquiryReplyCreateDtoSchema = z.object({
    content: z.string().min(1, '必須項目です'),
    userUuid: z.string().optional(),
})
export type InquiryReplyCreateDtoSchema = z.infer<typeof inquiryReplyCreateDtoSchema>

/**
 *
 */
export const clientConnectCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    ruby: z.string().nullable(),
    sort: z.number().int().positive(),
    uploadPermit: z.number().int(),
    afterPermit: z.number().int(),

    email: z.string().nullable(),
    message: z.string().nullable(),
    birthday: z.string().nullable(),
    deathDay: z.string().nullable(),
    sei: z.string().nullable(),
    mei: z.string().nullable(),
    relationship: z.string().nullable(),

    postalCode: z.string().nullable(),
    pref: z.string().nullable(),
    city: z.string().nullable(),
    address: z.string().nullable(),
    building: z.string().nullable(),
    tel: z.string().nullable(),
    mobilePhone: z.string().nullable(),
    tags: z.string().array().optional(),
    memo: z.string().nullable(),
    fileUuid: z.string().nullish(),
    isDelegated: z.number().int(),
})
export type ClientConnectCreateDtoSchema = z.infer<typeof clientConnectCreateDtoSchema>

export const clientConnectAddressUpdateDtoSchema = z.object({
    sei: z.string().min(1, '必須項目です'),
    postalCode: z.string().min(1, '必須項目です'),
    pref: z.string().min(1, '必須項目です'),
    city: z.string().min(1, '必須項目です'),
    address: z.string().min(1, '必須項目です'),
    building: z.string().nullable(),
})
export type ClientConnectAddressUpdateDtoSchema = z.infer<typeof clientConnectAddressUpdateDtoSchema>

export const clientConnectTagCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    sort: z.number().int().positive(),
})
export type ClientConnectTagCreateDtoSchema = z.infer<typeof clientConnectTagCreateDtoSchema>

/**
 *
 */
export const clientFamilyTreeCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
})
export type ClientFamilyTreeCreateDtoSchema = z.infer<typeof clientFamilyTreeCreateDtoSchema>

/**
 *
    export interface ClientMemoryCreateDto {
        name: string;
        content?: string | null;
        startAt?: string | null;
        endAt?: string | null;
        publish: boolean;
        dailyLife: boolean;
        fileUuid?: string | null;
    }
 */
const minimumYear = dayjs().year() - 100

export const clientMemoryCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    year: z
        .string()
        .min(4, '年の項目は4文字必要です。')
        .max(4, '年の項目は4文字必要です。')
        .refine((value) => !isNaN(parseInt(value)), { message: '年の項目は数字である必要がです。' })
        .refine(
            (value) => {
                const yearValue = parseInt(value)
                return yearValue >= minimumYear && yearValue <= 9999
            },
            { message: `年の項目は${minimumYear}以上である必要がです。` },
        ),
    month: z
        .string()
        .optional()
        .refine((value) => !value || !isNaN(parseInt(value)), { message: '月の項目は数字である必要がです。' })
        .refine(
            (value) => {
                if (value) {
                    const monthNumber = parseInt(value, 10)
                    return monthNumber >= 1 && monthNumber <= 12
                }
                return true
            },
            { message: '月の項目は1から12の値が必要です。' },
        ),
    content: z.string().min(1, '必須項目です'),
    fileUuid: z.string().nullish(),
})
export type ClientMemoryCreateDtoSchema = z.infer<typeof clientMemoryCreateDtoSchema>

export const clientMemoryCommentCreateDtoSchema = z.object({
    comment: z.string().min(1, '必須項目です'),
})
export type ClientMemoryCommentCreateDtoSchema = z.infer<typeof clientMemoryCommentCreateDtoSchema>

export const clientMemoryAssetCreateDtoSchema = z.object({
    fileUuids: z.array(z.string()),
})
export type ClientMemoryAssetCreateDtoSchema = z.infer<typeof clientMemoryAssetCreateDtoSchema>

export const clientGraveCreateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    posthumousName: z.string().max(10, '10文字以内で入力してください').optional().nullable(),
    denomination: z.string().max(20, '20文字以内で入力してください').optional().nullable(),
    fileUuid: z.string().nullable().optional(),
    vertical: z.boolean(),
})
export type ClientGraveCreateDtoSchema = z.infer<typeof clientGraveCreateDtoSchema>

export const clientGraveCommentCreateDtoSchema = z.object({
    comment: z.string().min(1, '必須項目です'),
})
export type ClientGraveCommentCreateDtoSchema = z.infer<typeof clientGraveCommentCreateDtoSchema>

export const clientGiftOrderCreateDtoSchema = z.object({
    deliveredAt: z.string().nullable().optional(),
})
export type ClientGiftOrderCreateDtoSchema = z.infer<typeof clientGiftOrderCreateDtoSchema>

export const clientGiftOrderCardNameDtoSchema = z.object({
    cardName: z.string().min(1, '必須項目です'),
})
export type ClientGiftOrderCardNameDtoSchema = z.infer<typeof clientGiftOrderCardNameDtoSchema>

export const clientConnectMessageDtoSchema = z.object({
    message: z.string().min(1, '必須項目です'),
})
export type ClientConnectMessageDtoSchema = z.infer<typeof clientConnectMessageDtoSchema>

export const clientSponsorFormDtoSchema = z.object({
    bannerImageUuid: z.string().nullish(),
    name: z.string().min(1, '必須項目です'),
    nameKana: z.string().min(1, '必須項目です'),
    email: z.string().email('メールアドレスの形式で入力してください').min(1, '必須項目です'),
    address: z.string().min(1, '必須項目です'),
    phoneNumber: z.string().min(1, '必須項目です'),
    siteUrl: z.string().nullable().optional(),
    categoriesUuids: z
        .string()
        .array()
        .refine((data) => data.length > 0, {
            message: '少なくとも1つのカテゴリーを選択してください。',
        }),
    coveredPrefectureUuids: z
        .string()
        .array()
        .refine((data) => data.length > 0, {
            message: '少なくとも1つの都道府県を選択してください。',
        }),
})

export type ClientSponsorFormDtoSchema = z.infer<typeof clientSponsorFormDtoSchema>

const JapaneseEra = [
    { era: 0, name: '明治', start: { year: 1868, month: 10 }, end: { year: 1912, month: 7 } }, // 1868/10 - 1912/07
    { era: 1, name: '大正', start: { year: 1912, month: 7 }, end: { year: 1926, month: 12 } }, // 1912/07 - 1926/12
    { era: 2, name: '昭和', start: { year: 1926, month: 12 }, end: { year: 1989, month: 1 } }, // 1926/12 - 1989/01
    { era: 3, name: '平成', start: { year: 1989, month: 1 }, end: { year: 2019, month: 4 } }, // 1989/01 - 2019/04
    { era: 4, name: '令和', start: { year: 2019, month: 5 }, end: null }, // 2019/05 - current
] as const

// 元号を検証する関数
const validateJapaneseDate = (year: number, month: number, japaneseEra?: number) => {
    // 入力された元号が和暦であり、かつ選択された元号がnullでない場合、次の処理を行います。
    const era = JapaneseEra.find((e) => e.era === japaneseEra)
    if (!era) return false

    // 入力された年に元号の開始年を加算して、調整された年を算出します。
    const westernYear = year + era.start.year - 1 // 由于计算从起始年开始，所以要加上-1 / -1 is added because the counting starts from start year

    // 元号の開始日と終了日を設定します。
    const startDate = new Date(era.start.year, era.start.month - 1)
    const endDate = era.end ? new Date(era.end.year, era.end.month - 1) : new Date()
    const westernDate = new Date(westernYear, month - 1)

    return westernDate >= startDate && westernDate <= endDate
}

export const clientUserPersonalHistoryCreate = z
    .object({
        japaneseEra: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3), z.literal(4)]).optional(),
        title: z.string().min(1, '必須項目です'),
        year: z.string({ required_error: '必須項目です' }).min(1, '必須項目です'),
        month: z.string({ required_error: '必須項目です' }).min(1, '必須項目です'),
        content: z.string().optional(),
        japaneseYear: z.boolean().default(true),
    })
    .superRefine((data, context) => {
        const year = parseInt(data.year)
        const month = parseInt(data.month)
        if (data.japaneseYear) {
            const isValid = validateJapaneseDate(year, month, data.japaneseEra)
            if (!isValid) {
                context.addIssue({
                    message: ' 元号に基づいて年と月を入力してください。',
                    path: ['month'],
                    fatal: true,
                    code: 'custom',
                })
            }
        }
    })
export type ClientUserPersonalHistoryCreate = z.infer<typeof clientUserPersonalHistoryCreate>

export const clientRelativeGraveCreateDtoSchema = z.object({
    posthumousName: z.string().min(1, '必須項目です').max(10, '10文字以内で入力してください'),
    denomination: z.string().max(20, '20文字以内で入力してください').nullable(),
    graveFile: z.string().nullable().optional(),
})
export type ClientRelativeGraveCreateDtoSchema = z.infer<typeof clientRelativeGraveCreateDtoSchema>

export const clientEndingNoteItemCreateDtoSchema = z.object({
    itemName: z.string().min(1, '必須項目です'),
    content: z.string().nullable(),
})
export type ClientEndingNoteItemCreateDtoSchema = z.infer<typeof clientEndingNoteItemCreateDtoSchema>

export const clientEndingNoteCategoryCreateDtoSchema = z.object({
    categoryName: z.string().min(1, '必須項目です'),
})
export type clientEndingNoteCategoryCreateDtoSchema = z.infer<typeof clientEndingNoteCategoryCreateDtoSchema>
